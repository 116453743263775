import PropTypes from 'prop-types';
import { Fragment } from 'react';

// Utils
import { cn } from 'utils';

// Styles
import styles from './CardsCarousel.module.css';

// Hooks
import useCarousel from '../../../src/hooks/useCarousel';

function CardsCarousel({
  list,
  className,
  children,
  uniqueKey,
  limit,
  theme,
}) {
  const {
    activeLists,
    emblaRef,
  } = useCarousel(list, {});

  return (
    <div>
      <div className={cn([styles.items, styles.desktop, className])}>
        {list?.length ? list
          .slice(0, limit)
          .map((item) => (
            <Fragment key={`${item?.data?.[uniqueKey]}`}>
              {children(item !== null ? item : { data: {} })}
            </Fragment>
          )) : null}
      </div>
      <div className={cn([styles.items, className, styles.tablet])}>
        {activeLists?.length ? (
          <div className={cn([styles.carousel, styles[theme], 'carousel'])} ref={emblaRef}>
            <div className={styles.carouselContainer}>
              {activeLists
                .slice(0, limit)
                .map((item) => (
                  <div key={`${item?.data?.[uniqueKey]}`} className={cn([styles.carouselSlide, 'carouselSlide'])}>
                    <div className={cn([styles.slideCard, 'slideCard'])}>
                      <div className={cn([styles.slideContainer, 'slideContainer'])}>
                        {children(item !== null ? item : { data: {} })}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

CardsCarousel.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
  className: PropTypes.string,
  uniqueKey: PropTypes.string.isRequired,
  limit: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.func,
  ]),
};

CardsCarousel.defaultProps = {
  list: [],
  theme: 'light',
  className: '',
  limit: 9999,
  children: null,
};

export default CardsCarousel;
