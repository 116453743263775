import { startCase } from 'lodash';
// Constants
import { VALID_PREFIXS_SLUGS } from '../constants';

/**
 * @param {string} slug
 * @param {string} locale
 * @param {string} prefix
 * @returns {string}
 */
export function completePageSlug(slug, locale, prefix) {
  return `${locale ? `${locale}-` : ''}${prefix ? `${prefix}-` : ''}${slug}`;
}

/**
 * @param {string} fullSlug
 * @param {string} locale
 * @returns {string}
 */
export function replacePageSlug(fullSlug, locale) {
  if (!fullSlug) return '';
  const withoutSlugPrefix = fullSlug.replace('slug:', '');

  const prefixRegex = new RegExp(`(${VALID_PREFIXS_SLUGS.join('|')})`);
  const match = withoutSlugPrefix.match(prefixRegex);
  const prefixToRemove = match ? match[1] : '';

  const withoutLocale = withoutSlugPrefix.replace(`${locale ? `${locale}-` : ''}`, '');

  return withoutLocale.replace(new RegExp(`^${prefixToRemove}-`), '');
}

export const pageSlugToTitle = (slug) => startCase(slug);
